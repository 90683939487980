<template>
  <div>
    <Header ref="header"/>

    <div class="full-main-n">
      <div class="w w1200 relative">
        <div class="crumbs-nav">
          <div class="crumbs-nav-main clearfix">
            <div class="crumbs-nav-item" v-for="(cate) in CrumbsCategories" v-bind:key="cate.id">
              <div class="menu-drop">
                <div class="trigger bottom">
                  <router-link :to="{path:'category',query:{id:cate.id}}" target="_blank" tag="a">
                    <span>{{cate.name}}</span>
                  </router-link>
                </div>
              </div>
              <i class="iconfont icon-right"></i>
            </div>
            <span class="cn-goodsName">{{ detail.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="w w1200">
        <div class="product-info mt20">
          <goods-images :images="images"/>
          <div class="product-wrap">
            <div class="name">{{ detail.name }}</div>
            <div class="tip">
              <p>温馨提示：部分商品包装更换频繁，如货品与图片不完全一致，请以收到的商品实物为准。</p>
            </div>
            <div class="brief" v-if="detail.subName!='' && detail.subName!=null">
              <p v-for="(b,index) in detail.subName.split('$$')" v-bind:key="index">
                {{b}}
              </p>
            </div>
            <div class="summary">
              <div class="summary-price-wrap">
                <div class="s-p-w-wrap">
                  <div class="summary-item si-shop-price">
                    <div class="si-tit">商 城 价</div>
                    <div class="si-warp">
                      <strong class="shop-price"><em>¥</em>{{ detail.price }}</strong>
                    </div>
                  </div>
                  <div class="summary-item si-market-price" v-if="detail.marketPrice">
                    <div class="si-tit">市 场 价</div>
                    <div class="si-warp">
                      <div class="m-price"><em>¥</em>{{ detail.marketPrice }}</div>
                    </div>
                  </div>
                  <div class="clear"></div>
                </div>
              </div>
              <div class="summary-basic-info">
                <div class="summary-item is-integral" v-for="(para,index) in detail.paras" v-bind:key="index">
                  <div class="si-tit" v-if="para.value">{{para.name}}</div>
                  <div class="si-warp" v-if="para.value">{{para.value}}</div>
                </div>

                <div class="summary-item is-number">
                  <div class="si-tit">数量</div>
                  <div class="si-warp">
                    <amount-box v-model="nums" :max="99999" :min="0" @keyup.enter.native="addCart"></amount-box>
                  </div>
                </div>
                <div class="clear"></div>
              </div>
              <div class="choose-btns ml60 clearfix">
                <a href="javascript:void(0);" v-on:click="addCart" class="btn-append"><i class="iconfont icon-carts"></i>加入购物车</a>
                <a href="javascript:void(0);" v-on:click="buyNow" class="btn-buynow">立即购买</a>
              </div>
            </div>
          </div>

          <div class="track">
            <div class="track_warp">
              <div class="track-tit">
                <h3>看了又看</h3>
                <span></span>
              </div>
              <swiper ref="mySwiper" :options="swiperOptions" style="overflow:hidden; position:relative; height:555px">
                <swiper-slide v-for="(good,index) in looks" v-bind:key="index" style="width: 140px;height: 180px;">
                  <div class="p-img">
                    <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank" tag="a">
                      <img :src="getCoverImage(good.images)" style="width: 140px !important;height: 140px !important;">
                    </router-link>
                  </div>
                  <div class="p-name">
                    <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank" tag="a">
                      {{good.name}}
                    </router-link>
                  </div>
                  <div class="price">
                    <em>¥</em>{{good.price}}
                  </div>
                </swiper-slide>
              </swiper>
              <div class="track-more">
                <a href="javascript:void(0);" class="sprite-up prevStop" v-on:click="prev"><i class="iconfont icon-up"></i></a>
                <a href="javascript:void(0);" class="sprite-down" v-on:click="next"><i class="iconfont icon-down"></i></a>
              </div>
            </div>
          </div>

          <div class="clear"></div>
        </div>


        <div class="goods-main-layout">
          <div class="g-m-left">
            <div class="g-main g-history">
              <div class="mt">
                <h3>最近浏览</h3>
                <a v-on:click="clearViews" class="clear_history ftx-05 fr mt10 mr10" href="javascript:void(0);">清空</a>
              </div>
              <div class="mc">
                <div class="mc-warp">
                  <div class="history_tishi" v-if="views.length==0">
                    您已清空最近浏览过的商品<br>
                    <router-link :to="{path:'/'}" class="ftx-05" tag="a">去购物</router-link>
                  </div>
                  <ul v-else>
                    <li v-for="(good) in views" v-bind:key="good.id">
                      <div class="p-img">
                        <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank"  tag="a">
                          <img :src="getCoverImage(good.images)" width="170" height="170">
                        </router-link>
                      </div>
                      <div class="p-name">
                        <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank"  tag="a">
                          {{good.name}}
                        </router-link>
                      </div>
                      <div class="p-lie">
                        <div class="p-price">
                          <em>¥</em>{{good.price}}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="g-m-detail">
            <div class="gm-tabbox" ectype="gm-tabs">
              <ul class="gm-tab">
                <li class="curr">商品详情</li>
              </ul>
              <div class="gm-tab-qp-bort" style="width: 1903px; left: -581.5px;"></div>
            </div>
            <div class="gm-floors">
              <div class="gm-f-item gm-f-details">
                <div v-html="detail.intro" v-if="detail.intro" style="text-align: center"></div>
                <div v-else style="text-align: center">
                  <p><img :src="images[0]"/></p>
                </div>
              </div>
            </div>
          </div>
          <div class="clear"></div>
          <div class="rection" id="guess_goods_love">
            <div class="ftit">
              <h3>猜你喜欢</h3>
            </div>
            <ul>
              <li v-for="(good) in guesses" v-bind:key="good.id">
                <div class="p-img">
                  <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank"  tag="a">
                    <img :src="getCoverImage(good.images)" width="134" height="134">
                  </router-link>
                </div>
                <div class="p-name">
                  <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank"  tag="a">
                    {{good.name}}
                  </router-link>
                </div>
                <div class="p-price">
                  <em>¥</em>{{good.price}}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import AmountBox from '@/components/AmountBox/Index'
import 'swiper/css/swiper.css'
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
import GoodsImages from '@/components/GoodsImages/Index'
import {getDetial, getGoodsSearch, cartCreate} from "@/api/good"
import {getCategoryCrumbs} from "@/api/category";

export default {
  name: "Good",
  components: {
    Header,
    Footer,
    GoodsImages,
    Swiper,
    SwiperSlide,
    AmountBox
  },
  data() {
    return {
      detail:{},
      guesses:[],     //  猜喜欢
      looks:[],       // 看了又看
      views:[],
      CrumbsCategories:[],
      images:[],
      nums: 0,
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 0,
        direction:'vertical'
      }
    }
  },
  created() {
    this.getDetail();
    this.getGuesses();
    this.getLooks();
    this.getViews();

    if(localStorage.getItem('ids')==null){
      let viewIds = new Array()
      viewIds.push(this.$route.query.id)
      localStorage.setItem("ids", JSON.stringify(viewIds))
    }else{
      let viewIds = JSON.parse(localStorage.getItem("ids"))
      if(!viewIds.includes(this.$route.query.id)){
        viewIds.push(this.$route.query.id)
      }
      viewIds = viewIds.reverse()
      viewIds = viewIds.slice(0,3) //只取3个
      localStorage.setItem("ids", JSON.stringify(viewIds))
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted() {
    this.$refs.header.hideCate();
    //this.swiper.slideTo(3, 1000, false)
  },
  methods:{
    getDetail(){
      getDetial(this.$route.query.id).then(res => {
        this.detail = res.data;
        this.getCrumbsCategories();
        document.title= this.detail.name + '_' + document.title;

        // if(this.detail.image.indexOf("image.yabyy.com") == -1){
        //   this.detail.image = settings.AdminUrl+this.detail.image
        // }

        // let imgs = []
        // imgs.push(this.detail.image)
        // this.detail.images.split(',').forEach((img)=>{
        //   if(img.indexOf("image.yabyy.com") == -1){
        //     imgs.push(settings.AdminUrl+img)
        //   }
        // })

        // 规格
        if(this.detail.paramsData != null){
          this.detail.paras = JSON.parse(this.detail.paramsData.replace(/[\r|\n|\t]/g,''))
        }else{
          this.detail.paras = []
        }

        // 轮播图
        let imgs = JSON.parse(this.detail.images)
        this.images = imgs.map((item)=>{
          console.log('=========================')
          console.log(imgs)
          return item.attachmentId == '' ? this.staticUrl + '/app/no-pic.png' : this.staticUrl + '/' + item.imageUrl

        })


      })
    },
    getCrumbsCategories(){
      // 取第一个非空值
      if(this.detail.categoryies != null){
        let categoryId = this.detail.categoryies.split(',').find((item) => {
          return item != ''
        })
        getCategoryCrumbs(categoryId).then(res => {
          this.CrumbsCategories = res.data.reverse()
        });
      }
    },
    getGuesses(){
      // getGoodsRecommendList({
      //   id:5
      // }).then(res => {
      //   this.guesses = res.data
      //   this.guesses.forEach((i)=>{
      //     if(i.image.indexOf("image.yabyy.com") == -1){
      //       i.image = settings.AdminUrl + i.image
      //     }
      //   })
      // })
      getGoodsSearch({
        currentPage: 1,
        pageSize: 5,
        sidx: 'newid()',
      }).then(res => {
        this.guesses = res.data.list;
        })
    },
    getViews(){
      if(localStorage.getItem('ids')==null){
        this.views = []
      }else{
        let viewIds = JSON.parse(localStorage.getItem("ids"));
        let where ={}
        where.ids = viewIds.toString()
        getGoodsSearch({
          currentPage: 1,
          pageSize: 9,
          sidx: 'id',
          ids: viewIds.toString()
        }).then(res => {
          this.views = res.data.list
          // this.views.forEach((i)=>{
          //   if(i.image.indexOf("image.yabyy.com") == -1){
          //     i.image = settings.AdminUrl + i.image
          //   }
          // })
        })
      }
    },
    getLooks(){
      getGoodsSearch({
        currentPage: 1,
        pageSize: 9,
        sidx: 'Visits',
      }).then(res => {
        this.looks = res.data.list;
      })
    },
    prev() {
      this.swiper.slidePrev();
    },
    next() {
      this.swiper.slideNext();
    },
    clearViews(){
      localStorage.removeItem('ids')
      this.views = []
    },
    addCart(){
      if(this.nums <= 0){
        this.$message({
          message: '购物车数量必须大于0',
          offset: 80,
          type: 'warning'
        });
      }else{
        let _this = this;

        cartCreate({
          goodsId: this.detail.id,
          goodsNo: this.detail.no,
          qty: this.nums,
          price: this.detail.price,
          signId: 2,
          skuId: this.detail.skus[0].id,
          specInfo : this.detail.skus[0].specValue
        }).then((res) => {
          if(res!=undefined){
            _this.$message({
              message: '加入购物车成功',
              type: 'success'
            });
          }
        }).catch(() => {

        });

        // addCart({
        //   goodsId: this.detail.id,
        //   num: this.nums
        // }).then(res => {
        //   this.$message({
        //     message: res.msg,
        //     type: 'success'
        //   });
        // })
      }
    },
    buyNow(){
      //console.log('*************************')
      //console.log(this.detail)
      if(this.nums <= 0){
        this.$message({
          message: '购买数量必须大于0',
          type: 'warning'
        });
      }else{
        this.$router.push({path:'/buy',query:{id:this.detail.id,num:this.nums}});
        // addCart({
        //   productId: this.detail.product.id,
        //   nums: this.nums,
        //   type: 2
        // }).then(res => {
        //   if(res.status){
        //     this.$router.push({path:'/buy',query:{ids:res.data}});
        //   }else{
        //     this.$message({
        //       message: res.msg,
        //       type: 'success'
        //     });
        //   }
        //
        //
        // })
      }
    },
    getCoverImage(images){
      let json = JSON.parse(images)
      return json[0].attachmentId == '' ? this.staticUrl + '/app/no-pic.png' : this.staticUrl + '/' + json[0].imageUrl
    },
  }
}
</script>

<style scoped>
.menu-drop{
  background-color: #f8f8f8;
}
.gm-f-details{
  margin-top: 28px;
}

.track-more {
  text-align: center;
  font-size: 0;
  height: 24px;
  line-height: 24px;
  margin-top: 10px;
}
.track-more .sprite-up {
  margin-right: 20px;
}
.track-more .iconfont {
  font-size: 24px;
  color: #8c8c8c;
}
.p-name {
  width: 100%;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
}

.p-name a {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.price{
  width: 100%;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #f42424;
}

.brief{
  background-color: #F56C6C;
  padding: 12px 5px 12px;
  color: #fff;
  margin-bottom: 8px;
}

.tip{
  background-color: #e6a23c;
  padding: 12px 5px 12px;
  color: #fff;
  margin-bottom: 8px;
}
</style>
