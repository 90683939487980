<template>
  <div class="preview">
    <div class="gallery_wrap">
      <pic-zoom :url="images[imageIndex]" v-if="images.length > 0" :scale="2"></pic-zoom>
    </div>
    <div class="spec-list">
      <a href="javascript:void(0);" class="spec-prev prevStop">
        <i class="iconfont icon-left"></i>
      </a>
      <div class="spec-items">
        <ul>
          <li data-type="img" v-for="(image,index) in images" v-bind:key="index" @mouseover="imageIndex=index">
            <a href="javascript:void(0);" v-if="index == imageIndex" class="MagicThumb-swap img-hover" style="outline: none; display: inline-block;">
              <img :src="image" width="58" height="58">
            </a>
            <a href="javascript:void(0);" v-else class="MagicThumb-swap" style="outline: none; display: inline-block;">
              <img :src="image" width="58" height="58">
            </a>
          </li>
        </ul>
      </div>
      <a href="javascript:void(0);" class="spec-next nextStop"><i class="iconfont icon-right"></i></a>
    </div>
  </div>
</template>

<script>
import PicZoom from 'vue-piczoom'
export default {
  name: "GoodsImages",
  components:{
    PicZoom
  },
  data(){
    return{
      imageIndex: 0
    }
  },
  props: {
    images: {
      type: Array
    }
  },
  created() {

  }
}
</script>

<style scoped>

</style>
